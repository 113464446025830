// import { Input } from 'element-ui'
import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import store from '@/store/index'
import router from '@/routes/index'
import '@/static/font-icon.css'
import { MessageBox, Message } from 'element-ui'
Vue.config.productionTip = false
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message

// Vue.use(Input)
new Vue({

    render: h => h(App),
    store,
    router,
}).$mount('#app')