<template>
  <div id="app">
   <keep-alive>
<router-view ></router-view>
   </keep-alive>
   <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  </div>
</template>

<script>

export default {
  name: 'app',
 
  components: {
  },
  computed:{
    show(){
      return this.$router.options.routes[1].meta.keepAlive
    }
  }
}
</script>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                
<style>

*{
  margin: 0;
  padding: 0;
}
body{
  background: rgb(255, 255, 255);
}
#app {
  width: 100vw;
  height: 100vh;
  /* background: rgb(24, 25, 35); */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
