import { yuyueset, setjzorder, setwjorder } from '@/api/zwkj/index'
const actions = {
    async yuyueset(context, data) {
        const res = await yuyueset(data);
        console.log(res)
        return res.statu
    },
    async setjzorder(context, data) {
        console.log(data)
        const res = await setjzorder({...data });
        console.log(res)
            // console.log(res);
        return res
    },
    async setwjorder(context, data) {
        const res = await setwjorder({...data })
        return res
    }
};
const mutations = {
    GETSEARCHLIST(state, datas) {
        let { data, data1 } = datas
        state.searchlist = data;
        state.nums = data1

    }
};
const state = {
    searchlist: [],
    nums: 0
};
const getters = {};
export default {
    actions,
    mutations,
    state,
    getters
}