import Vue from 'vue'
import { Pagination, Popconfirm, Button, Input, Radio, Dialog, Form, FormItem, DatePicker, Table, TableColumn, Image, Drawer, Select, Option, Upload, Menu, MenuItem, Tabs, TabPane, Carousel, CarouselItem, Backtop, Icon, RadioGroup, Checkbox, CheckboxGroup } from 'element-ui'


Vue.use(Button);
Vue.use(Input);
Vue.use(Radio);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Image)
Vue.use(Drawer)
Vue.use(Select)
Vue.use(Option)
Vue.use(Popconfirm)
Vue.use(Upload)
Vue.use(Pagination)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dialog)
Vue.use(Backtop)
Vue.use(Select)
Vue.use(Option)
Vue.use(Icon)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
    // Vue.use(MessageBox)