import { getregister, getlogin } from '../../api/home/index'
import { addtoken, gettoken, removetoken, getusername } from './token';
const actions = {
    async putregister(context, datas) {
        const data = await getregister(datas);
        console.log(data);
        if (data.data == '-1') {
            return '账号已注册'
        } else if (data.data == '-2') {
            return '用户名已经被占用'
        } else {
            return '注册成功'
        }

    },
    async putlogin(context, datas) {
        const data = await getlogin(datas);
        console.log(data);
        if (data.data != '-1') {

            context.commit('PUTLOGIN', data.data);
            return 1;
        } else {
            return 0
        }
    },
    async removetoken(context) {
        removetoken();
        context.commit("REMOVETOKEN");
    }

}
const mutations = {
    PUTLOGIN(state, datas) {
        let data = datas.token;
        let { name } = datas;
        console.log(name)
        state.token = data;
        state.name = name;
        addtoken(data, name);

    },
    REMOVETOKEN(state) {
        state.token = ""
        state.name = ""
    }

}
const state = {
    token: gettoken() || '',
    name: getusername() || '',

}
const getters = {

}
export default {
    actions,
    mutations,
    state,
    getters
}