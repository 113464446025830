import { getallcollect } from '@/api/collect/index'
const actions = {
    async getallcollect(context) {
        let data = await getallcollect();
        if (data.data != '-1') {
            context.commit("GETALLCOLLECT", data.data)
        }
    }
}
const mutations = {
    GETALLCOLLECT(state, data) {
        state.collectlist = data
    }
};
const state = {
    collectlist: []
};
const getters = {}
export default {
    actions,
    mutations,
    state,
    getters
}