import { updateorder, getorderlist, deleteorder, gettelorderlist, getdateorderlist, payorder, putcomment } from '@/api/order/index'
const actions = {
    // 修改订单状态
    async updateorder(context, datas) {
        let data = await updateorder(datas)
        console.log(data)
    },
    // 评论
    async putcomment(context, datas) {
        let data = await putcomment(datas);
        if (data.data.affectedRows == '1') {
            return 1;
        } else {
            return 0;
        }
    },
    // 订单
    async payorder(context, datas) {
        let data = await payorder(datas);
        if (data.data.data.affectedRows == '1') {
            return data.data.orderid;
        } else {
            return 0;
        }
    },
    // 全部订单
    async getorder(context) {
        let data = await getorderlist();
        if (data.data != '-1') {
            console.log(data);
            context.commit('GETORDER', data.data);
        }
    },
    // 电话查询订单
    async gettelorderlist(context, tel) {
        let data = await gettelorderlist(tel);
        console.log(data);
        context.commit("GETORDER", data.data);
    },
    // 日期查询订单
    async getdatelist(context, date) {
        let data = await getdateorderlist(date)
        context.commit("GETORDER", data.data);
        console.log(data.data)
    },
    async deleteorder(context, data) {
        let datas = await deleteorder(data);
        if (datas.data != -1) {
            return "删除成功"
        } else {
            return "删除失败"
        }
    }

}
const mutations = {
    GETORDER(state, data) {
        for (let item of data) {
            // console.log(item, 'item')
            let endTime = item.endTime;
            let data = endTime.split('-');
            let year = new Date().getFullYear();
            let month = new Date().getMonth();
            let day = new Date().getDate();
            if (year == data[0]) {
                if (month + 1 == data[1]) {
                    if (day > data[2]) {
                        item.show = true

                    } else {
                        item.show = false;
                    }
                } else {
                    item.show = true


                }
            } else {
                item.show = true

            }
        }
        state.orderlist = data;
    }

}
const state = {
    orderlist: [],

}
const getters = {

}
export default {
    actions,
    mutations,
    state,
    getters
}