export const addtoken = (data, name) => {
    localStorage.setItem('USERNAME', name)
    return localStorage.setItem("TOKEN", data);

}
export const gettoken = () => {
    return localStorage.getItem("TOKEN");
}
export const getusername = () => {
    return localStorage.getItem("USERNAME")
}
export const removetoken = () => {
    localStorage.removeItem("USERNAME")
    return localStorage.removeItem("TOKEN");
}