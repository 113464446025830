<template>
  <div style="width:100%; height: 100%; margin: auto;position: relative; top: 0;font-family: '微软雅黑';">
   
      <video
        src="http://www.haikoutour.cn/img/sy/ABUIABA6GAAgxLrnggYo_8zd6AY.mp4"
        style="width: 100%;"
        autoplay="autoplay"
        loop="loop"
      ></video>
    <div style="position: fixed; width:100%;height:100px;top:0;z-index: 999;margin: auto;">
      <transition name="a">
      <div class="nav_box" v-if="showboxs" @mouseout="showbox2"   style="position: fixed;width:100%;">
      <div  style="opacity: 0; width:100%;height: 100px;background-color: red;position: relative;top: 310px;" @mouseover="showbox2"></div>
      </div>
    </transition>

    <div class="nav_item" @mouseover="showbox" v-if="!showboxs">
      <div style="width: 270px; margin-left: 300px; float: left;position: relative; left: 0px;">
        <!-- <transition name="title"></transition> -->
        <h2 class="h_box" style="margin-top: 10px;position: relative; left: 0;">中网科技(海南)有限公司</h2>
        <h2 class="h_box">CHINA-KJ.CN</h2>
      </div>

      <!-- <div style="width: 80px; font-weight: 330"></div> -->
      <div style="position: relative; left: 195px;display: flex;">
      <div
        class="select_box"
        
      >
        首页
      </div>

      <div
        class="select_box"
        
      >
        产品中心
      </div>
      <div
        class="select_box"
        
      >
        旗下网站
      </div>
      <div
        class="select_box"
        
      >
        关于我们
      </div>
      <div
        class="select_box"
       
      >
        联系我们
      </div>
    </div>
  </div>
    <div class="nav_item" @mouseover="showbox" v-if="showboxs" >
      <div style="width: 430px; margin-left: 300px; float: left;position: relative; left: 0px;">
        <!-- <transition name="title"></transition> -->
        <h2 class="h_box" style="margin-top: 10px; color: #0052d9">
          中网科技(海南)有限公司
        </h2>
        <h2 class="h_box" style="color: #0052d9">CHINA-KJ.CN</h2>
      </div>

      <!-- <div style="width: 200px"></div> -->
      <div style="position: relative; left: 105px;display: flex;">
      <div class="select_box" style="color: black; z-index: 99;">
        <div style="margin: 0" @mouseover="show2 = true">
          <div id="davidszhou">
            <div id="zongk">
              <ul>
                <li>
                  <p style="font-weight: 800;">首页 </p>
                </li>
                <li>
                  <div class="transition-box" style="margin: 0 auto"></div>
                </li>
                <!-- <li style="position: relative;top: -20px;">下拉式菜单</li>
                <li style="position: relative;top: -20px;">下拉式菜单</li>
                <li style="position: relative;top: -20px;">下拉式菜单</li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="select_box" style="color: black">
        <div style="margin: 0">
          <div id="zongk">
            <ul>
              <li ><p style="cursor: pointer;font-weight: 800;" >产品中心</p></li>
              <li>
                <div class="transition-box" style="margin: 0 auto"></div>
              </li>
              <li 
                style="position: relative; top: -20px; cursor: pointer"
               
              >
              <p class="li_box"><a href="https://zwdaohang.com" target="_blank">中网导航</a></p>
              <a href=""></a>
                
              </li>
              <li 
                style="position: relative; top: -20px; cursor: pointer"
              
              >
              <p class="li_box"><a href="https://sj.china-kj.net" target="_blank">中网设计</a></p>
                
              </li>
              <li
                style="position: relative; top: -20px; cursor: pointer"
               
              >
                
                <p class="li_box"><a href="https://tg.china-kj.net" target="_blank">中网推广</a></p>
              </li>
              <li
                style="position: relative; top: -20px; cursor: pointer"
                
              >
               
                <p class="li_box"> <a href="https://seo.china-kj.net" target="_blank">中网优化</a></p>
              </li>
              <li
                style="position: relative; top: -20px; cursor: pointer"
              
              >
              <p class="li_box"><a href="https://jz.china-kj.net" target="_blank">中网建站</a></p>
                
              </li>
              <li
                style="position: relative; top: -20px; cursor: pointer"
               
              >
              <p class="li_box"><a href="https://v.china-kj.net" target="_blank">微+平台 </a></p>
               
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="select_box" style="color: black">
        <div style="margin: 0">
          <div id="zongk">
            <ul>
              <li style="font-weight: 800;">旗下网站</li>
              <li>
                <div class="transition-box" style="margin: 0 auto"></div>
              </li>
              
              <li style="position: relative; top: -20px">
                <a href="http://www.hainan-ly.com/"  target="_blank"><p class="li_box">海南旅游网</p></a>
                
              </li>
              <li style="position: relative; top: -20px">
                <a href="https://www.hainan-jy.com/"  target="_blank"><p class="li_box">海南教育网</p></a>
                
              </li>
              <li style="position: relative; top: -20px">
              
                <a href="https://www.hainan-rc.com/"  target="_blank"><p class="li_box">海南人才网</p></a>
              </li>
              <li style="position: relative; top: -20px">
               
                <a href="https://hainan-fc.com/"  target="_blank"><p class="li_box">海南房产网</p></a>
              </li>
              <li style="position: relative; top: -20px">
              
                <a href="https://www.hainan-web.com/"  target="_blank"><p class="li_box" @mouseout="showbox2">海南网</p></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="select_box" style="color: black">
        <div style="margin: 0">
          <div id="zongk">
            <ul>
              <li style="font-weight: 800;"><router-link to="/about" target="_blank">关于我们</router-link></li>
              <li>
                <div class="transition-box" style="margin: 0 auto"></div>
              </li>
              <!-- <li style="position: relative;top: -20px;">下拉式菜单</li>
                <li style="position: relative;top: -20px;">下拉式菜单</li>
                <li style="position: relative;top: -20px;">下拉式菜单</li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="select_box" style="color: black">
        <div id="zongk">
          <ul>
            <li style="cursor: pointer;font-weight: 800;"><router-link to="/about" target="_blank">联系我们</router-link></li>
            <li>
              <div class="transition-box" style="margin: 0 auto"></div>
            </li>
            <!-- <li style="position: relative;top: -20px;">下拉式菜单</li>
                <li style="position: relative;top: -20px;">下拉式菜单</li>
                <li style="position: relative;top: -20px;">下拉式菜单</li> -->
          </ul>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div class="contnet_box" style="margin: auto;">
      <h1>分享全球智慧，共创美好未来</h1>
      <div style="font-size: 18px; margin-top: 10px; text-align: center;font-weight: 400;">
        推动科技创新与文化传承，助力企业升级连接数字生活
      </div>
    </div>
    <div class="js_box" style="height: 165px;margin-bottom: 60px;padding-top: 35px;">
      <div style="margin-bottom: 20px">中网服务遍布中国</div>
      <div style="margin-bottom: 20px">
        通过专业的技术与丰富的经验，助力企业数字化升级。
      </div>
      <div>我们的使命是"分享全球智慧共创美好未来"。</div>
    </div>
    <div class="serve_box">
 
      <div class="serve_bg" >
        <div class="serve_item">
       
          <div>
            <img
              src="http://www.haikoutour.cn/img/sy/gg1.png"
              style="width: 320px; height: 200px"
            />
          </div>
          <div style="text-align: center">
            <div>
              <h5
                style="
                  color: rgb(105, 105, 105);
                  font-size: 23px;
                  font-weight: 500;
                  padding-top: 13px;
                "
              >
                网站建设
              </h5>
            </div>
            <div
              style="
                color: rgb(105, 105, 105);
                font-size: 14px;
                font-weight: 100;
                padding-top: 10px;
              "
            >
              好设计才能促成好的生意<br />全程帮您建设企业网站,一天内建成
            </div>
          </div>
        </div>
      </div>
        <!--  -->
    
        <div class="serve_bg" >
      <div class="serve_item">
        <div>
          <img
            src="http://www.haikoutour.cn/img/sy/gg6.png"
            style="width: 320px; height: 200px"
          />
        </div>
        <div style="text-align: center">
          <div>
            <h5
              style="
                color: rgb(105, 105, 105);
                font-size: 23px;
                font-weight: 500;
                padding-top: 13px;
              "
            >
              中网推广
            </h5>
          </div>
          <div
            style="
              color: rgb(105, 105, 105);
              font-size: 14px;
              font-weight: 100;
              padding-top: 10px;
            "
          >
            覆盖全国主流媒体<br />帮您用最少的钱获得最高回报
          </div>
        </div>
      </div>
    </div>
    <div class="serve_bg" >
      <div class="serve_item">
        <div>
          <img
            src="http://www.haikoutour.cn/img/sy/gg3.png"
            style="width: 320px; height: 200px"
          />
        </div>
        <div style="text-align: center">
          <div>
            <h5
              style="
                color: rgb(105, 105, 105);
                font-size: 23px;
                font-weight: 500;
                padding-top: 13px;
              "
            >
              中网优化
            </h5>
          </div>
          <div
            style="
              color: rgb(105, 105, 105);
              font-size: 14px;
              font-weight: 100;
              padding-top: 10px;
            "
          >
            中网优化给您提供最佳优化方案<br />助力企业成长,和您一起发现潜在机遇
          </div>
        </div>
      </div>
    </div>
    <div class="serve_bg" >
      <div class="serve_item">
        <div>
          <img
            src="http://www.haikoutour.cn/img/sy/gg5.png"
            style="width: 320px; height: 200px"
          />
        </div>
        <div style="text-align: center">
          <div>
            <h5
              style="
                color: rgb(105, 105, 105);
                font-size: 23px;
                font-weight: 500;
                padding-top: 13px;
              "
            >
              公众号建设
            </h5>
          </div>
          <div
            style="
              color: rgb(105, 105, 105);
              font-size: 14px;
              font-weight: 100;
              padding-top: 10px;
            "
          >
            好公众号才能留住客户<br />帮您上线创建公众号共享微信10亿客户
          </div>
        </div>
      </div>
    </div>
    </div>
    <div style="background-color: rgb(24, 24, 24); height: 350px">
      <div
        style="
          width: 1920px;
          margin: auto;
          padding-top: 160px;
          text-align: center;
        "
      >
        <div
          style="
            color: rgb(194, 194, 194);
            font-size: 25px;
            font-weight: 400;
            line-height: 50px;
          "
        >
          我们在<font style="color: rgb(220, 204, 150)">客户</font
          >的要求上进行创新,<br />数十个领域,百余国内外企业的<font
            style="color: rgb(220, 204, 150)"
            >成功</font
          >合作,<br />验证了我们的<font style="color: rgb(220, 204, 150)"
            >价值</font
          >
        </div>
      </div>
    </div>
    <div class="con_box">
      <div class="con_item">
        <!-- <transition name="leftone_box">
          <div v-if="left_one" style="width:50px;height: 70px;margin-left: 50px;border-left:8px solid #0052D9;margin-top: 100px;" ></div> 
      </transition> -->

        <div class="left_box">
          <!-- 1 -->

          <div
            ref="abc"
            :class="one == 1 ? 'refbox' : 'refbox2'"
            @mouseover="oneHandler"
          >
            <div
            :class="one == 1 ? 'ref_icon1' : 'ref_icon2'"
              
                
            ></div>

            <!-- <transition name="leftonediv"> -->
            <div>
              <h3 style="font-size: 30px; color: white">连接用户与生活</h3>
              <p style="color: white; font-size: 16px; margin-top: 15px">
                让生活更便捷更多彩
              </p>
            </div>
            <!-- </transition> -->

            <!-- <transition name="leftoned">
      <div v-if="!left_one" style="position: absolute;left: 140px;">
          <h3 style="font-size: 30px;color: white;">连接用户与生活</h3>
          <p style="color: white;font-size: 16px; margin-top:15px">让生活更便捷更多彩</p>
      </div>
  </transition> -->
  <div style="opacity: 0;display:inline-block;width:200px;height: 250px;background-color:red;position: relative;top:-60px;left:-211px;z-index: 888;" @mouseover="oneHandler"></div>
          </div>

          <!-- 2 -->
          <div :class="two == 1 ? 'twobox' : 'twobox2'" @mouseover="twoHandler">
            <div
            :class="two == 1 ? 'two_icon1' : 'two_icon2'"
            ></div>
            <div>
              <h3 style="font-size: 30px; color: white">连接企业与科技</h3>
              <p style="color: white; font-size: 16px; margin-top: 15px">
                数字化助手，助力产业升级
              </p>
            </div>
            
          </div>
          <!-- 3 -->
          <div
          @mouseover="threeHandler"
            :class="treee == 1 ? 'threebox' : 'threebox2'"
           
          >
            <div
            :class="treee== 1 ? 'three_icon1' : 'three_icon2'"
            ></div>
            <div >
              <h3 style="font-size: 30px; color: white"  >连接现在与未来</h3>
              <p style="color: white; font-size: 16px; margin-top: 15px">
                探索面向未来的创新科技
              </p>
            </div>
          
          </div>
        </div>
        <!-- 第一个 -->
        <div class="right_box" v-show="num == 1">
          <div class="one_item">
            <div style="display: flex">
              <div class="lj_box">
                <h4 style="font-size: 24px; color: white" class="h4">连接世界</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    中网导航已成为千万网民的上网导航网站。为用户提供最纯净上网导航，最极速的上网体验，让上网回归本质。
                  </p>
                <!-- <div class="lj_item">
                 
                </div> -->
              </div>
              <div class="ly_box">
                <h4 style="font-size: 24px; color: white" class="h4">旅游休闲</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    海南旅游网作为国内领先的在线旅游媒体以及增值服务提供商。致力于向全球旅游爱好者提供全面、多彩的海南旅游资讯。
                  </p>
                <!-- <div class="ly_item">
               
                </div> -->
              </div>
            </div>
            <div style="display: flex">
              <div class="jy_box">
                <h4 style="font-size: 24px; color: white" class="h4">教育文化</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    海南教育网是服务于全民的领先在线教育资讯提供商，致力于向全民提供全面多彩的海南教育文化资讯。
                  </p>
                <!-- <div class="jy_item">
                 
                </div> -->
              </div>
              <div class="cx_box">
                <h4 style="font-size: 24px; color: white" class="h4">创新人才</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    海南人才网是国内领先的人才互动招聘网站，为企业发展注入新的人才，帮助求职者更快找到最佳的工作。
                  </p>
                <!-- <div class="cx_item">
                 
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 第二个 -->
        <div class="right_box" v-show="num == 2">
          <div class="one_item">
            <div style="display: flex">
              <div class="zw_box">
                <h4 style="font-size: 24px; color: white" class="h4">中网推广</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    通过丰富的互联网及全球各大媒体，使用最低成本，将企业及产品进行推广，从而达到目的。
                  </p>
                <!-- <div class="zw_item">
                 
                </div> -->
              </div>
              <div class="jsx_box">
                <h4 style="font-size: 24px; color: white" class="h4">网站建设</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    网站是企业和产品的门面，好设计才能促成好的生意，中网为您全程搭建企业网站，帮您做出生动且富有创意的网站。
                  </p>
                <!-- <div class="js_item">
                  
                </div> -->
              </div>
            </div>
            <div style="display: flex">
              <div class="dj_box">
                 <!-- <h4 style="font-size: 24px; color: white;">网站建设</h4>"> -->
                 <h4 style="font-size: 24px; color: white" class="h4">
                    公众号及小程序搭建
                  </h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    手机时代，万物互联，离不开公众号，花最少的钱，我们帮你一站式搞定，共同分享微信十亿用户。
                  </p>
                <!-- <div class="dj_item">
                 
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 第3个 -->
        <div class="right_box" v-show="num == 3">
          <div class="one_item">
            <div style="display: flex">
              <div class="zn_box">
                <h4 style="font-size: 24px; color: white" class="h4">人工智能</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    运用多种技术资产，提升人工智能实力，惠及人类和世界。
                  </p>
                <!-- <div class="zn_item">
                  
                </div> -->
              </div>
              <div class="hl_box">
                <h4 style="font-size: 24px; color: white" class="h4">互联网</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    通过中网连接世界,共创美好未来
                  </p>
                <!-- <div class="hl_item">
                  
                </div> -->
              </div>
            </div>
            <div style="display: flex">
              <div class="yl_box">
                <h4 style="font-size: 24px; color: white" class="h4">娱乐</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    海南娱乐网,国内领先娱乐平台,为您提供最全面咨询信息
                  </p>
                <!-- <div class="yl_item">
                  
                </div> -->
              </div>
              <div class="wl_box">
                <h4 style="font-size: 24px; color: white" class="h4">未来探索</h4>
                  <p style="color: white; font-size: 16px; margin-top: 20px" class="p1">
                    中网科技与您一起共同创造、探索新未来
                  </p>
                <!-- <div class="wl_item">
                  
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        margin: auto;
        font-size: 18px;
        color: rgb(94, 100, 100);
        width: 1150px;
      "
    >
      <div
        style="
          padding-top: 28px;
          padding-bottom: 25px;
          border-bottom: 1px solid rgb(241, 241, 241);
          overflow: hidden;
        "
      >
        <div style="float: left">关注我们</div>
        <div class="wechtimg"></div>
      </div>
    </div>
    <div
      style="
        margin: auto;
        font-size: 18px;
        color: rgb(94, 100, 100);
        width: 1150px;
        overflow: hidden;
      "
    >
      <div
        style="
          padding-top: 40px;
          padding-bottom: 25px;
          width: 550px;
          float: left;
        "
      >
        <div style="width: 183px; float: left">
          关于我们
          <div class="bannertext1" style="padding-top: 30px">  <a href="https://china-kj.net/about" target="_blank">联系我们</a></div>
            <div class="bannertext1"><a href="https://china-kj.net/about" target="_blank">合作洽谈</a></div>
            <div class="bannertext1"><a href="https://china-kj.net/about" target="_blank">客户服务</a></div>
          <a
            href="http://hainanly.cn"
            style="text-decoration: none; color: rgb(75, 75, 75)"
            ><div class="bannertext1">海南旅游</div></a
          >
        </div>
        <div style="width: 183px; float: left">
          旗下产品
          <div class="bannertext1" style="padding-top: 30px"> <a href="https://zwdaohang.com" target="_blank">中网导航</a></div>
            <div class="bannertext1"><a href="https://tg.china-kj.net" target="_blank">中网推广</a></div>
            <div class="bannertext1"> <a href="https://seo.china-kj.net" target="_blank">中网优化</a></div>
            <div class="bannertext1"><a href="https://jz.china-kj.net" target="_blank">中网建站</a></div>
            <div class="bannertext1"><a href="https://sj.china-kj.net" target="_blank">中网设计</a></div>
            <div class="bannertext1"><a href="https://v.china-kj.net" target="_blank">微+平台 </a></div>
        </div>
        <div style="width: 183px; float: left">
          法律信息
          <div class="bannertext1" style="padding-top: 30px"><a href="https://china-kj.net/zs" target="_blank">权利声明</a></div>
            <div class="bannertext1"><a href="https://china-kj.net/zs" target="_blank">隐私政策</a></div>
            <div class="bannertext1"><a href="https://china-kj.net/zs" target="_blank">知识产权</a></div>
        </div>
      </div>
      <div
        style="
          padding-top: 20px;
          padding-bottom: 25px;
          width: 546px;
          float: left;
        "
      >
        <img
          src="http://www.haikoutour.cn/img/sy/zwlogo.png"
          width="320px"
          style="margin-left: 140px"
        />
      </div>
    </div>
    <div style="padding-top: 45px; padding-bottom: 50px">
      <div
        style="
          width: 1150px;
          margin: auto;
          font-size: 13px;
          color: rgb(94, 100, 100);
        "
      >
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010802000651"
          style="text-decoration: none; color: rgb(94, 100, 100)"
          >琼公网安备 46010802000651号</a
        >&nbsp;&nbsp;&nbsp;
        <a
          href="https://beian.miit.gov.cn/"
          style="text-decoration: none; color: rgb(94, 100, 100)"
          >琼ICP备2020003380号-2</a
        >
        <font style="margin-left: 240px"
          >Copyright © 2021 China Network Technology (Hainan) Co.Ltd 中网科技
          版权所有</font
        >
      </div>
    </div>
    <!-- <transition name="s">
      <div class="s10" v-if="s10" @mouseout="s00">
          
      <p></p>
      </div>
  </transition> -->

    <!-- 测试1 -->
    <!-- <el-button @click="show2 = !show2">Click Me</el-button> -->

   
  </div>
</template>
  
  <script>
import "element-ui/lib/theme-chalk/base.css";
export default {
  name: "ShoYe",
  data() {
    return {
      one: 1,
      two: 1,
      treee: 1,
      showboxs: false,
      show2: false,
      left_one: false,
      num: 1,
    };
  },
  mounted() {
    this.oneHandler();
  },
  methods: {
    goDh(){
      this.$router.push({
        path:'/dh'
      })
    }, 
    goZs(){
      this.$router.push({ path: "/test" });
    },
    goWj() {
      this.$router.push({ path: "/wj" });
    },
    goSj() {
      this.$router.push({ path: "/sj" });
    },
    goJz() {
      this.$router.push({ path: "/jz" });
    },
    goYh() {
      this.$router.push({ path: "/yh" });
    },
    goTg() {
      this.$router.push({ path: "/tg" });
    },
  
    goGy(){
         this.$router.push({path:'/guanyu'})
    },
    threeHandler() {
      this.treee = 1;
      this.one = 2;
      this.two = 2;
      this.num = 3;
    },
    twoHandler() {
      this.two = 1;
      this.one = 2;
      this.treee = 2;
      this.num = 2;
    },
    testhan() {
      // this.one=1
    },
    testHandler() {
      //   this.one=2
    },

    oneHan() {
      // this.one=2;
    },
    oneHandler() {
      this.treee = 2;
      this.two = 2;
      this.one = 1;
      this.num = 1;
    },

    showbox() {
      this.showboxs = true;
    },
    showbox2() {
      this.showboxs = false;
    },
    oneruko() {},
  },
  beforeRouteLeave(to,from,next){
  this.$destroy()
next()
}
};
</script>
  
  <style lang="css" scoped>
.cx_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  opacity: 0.9;
  width: 460px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/hnrcw.png");
}
.cx_box:hover .h4{
  transform: translateY(-60px);
}
.cx_box:hover .p1{
  transform: translateY(-90px);
}
/* .cx_item {
  transition: all 0.5s;
  position: relative;
  bottom: -60px;
}
.cx_box:hover .cx_item {
  transform: translateY(-80px);
} */
.jy_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  opacity: 0.9;
  width: 540px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/hainanjiaoyv.png");
  padding: 0 40px;
  box-sizing: border-box;
}
/* .jy_item {
  transition: all 0.5s;
  position: relative;
  bottom: -80px;
}
.jy_box:hover .jy_item {
  transform: translateY(-80px);
} */
.jy_box:hover .h4{
  transform: translateY(-60px);
}
.jy_box:hover .p1{
  transform: translateY(-90px);
}
.ly_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  opacity: 0.9;
  width: 460px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/hainanly.png");
  background-position: center;
  background-size: cover;
}
.ly_box:hover .h4{
  transform: translateY(-60px);
}
.ly_box:hover .p1{
  transform: translateY(-90px);
}
/* .ly_item {
  transition: all 0.5s;
  position: relative;
  bottom: -80px;
}
.ly_box:hover .ly_item {
  transform: translateY(-80px);
} */
.lj_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  opacity: 0.9;
  width: 540px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/zwdaohang.png");
  padding: 0 40px;
  box-sizing: border-box;
}
.lj_box:hover .h4{
  transform: translateY(-60px);
}
.lj_box:hover .p1{
  transform: translateY(-90px);
}
/* .lj_item {
  transition: all 0.5s;
  position: relative;
  bottom: -80px;
} */
/* .lj_box:hover .lj_item {
  transform: translateY(-80px);
} */
.wl_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  opacity: 0.9;
  width: 460px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/U3c9786e0a607410ea81bf7b9cfc16227s.jpg");
}
.wl_box:hover .h4{
  transform: translateY(-60px);
}
.wl_box:hover .p1{
  transform: translateY(-90px);
}
/* .wl_item {
  transition: all 0.5s;
  position: relative;
  bottom: -40px;
} */
/* .wl_box:hover .wl_item {
  transform: translateY(-80px);
} */
.yl_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  opacity: 0.9;
  width: 460px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/Ubc029c21b895410097ecf78c54824971w.jpg");
}
.yl_box:hover .h4{
  transform: translateY(-60px);
}
.yl_box:hover .p1{
  transform: translateY(-90px);
}
/* .yl_item {
  transition: all 0.5s;
  position: relative;
  bottom: -40px;
} */
/* .yl_box:hover .yl_item {
  transform: translateY(-80px);
} */
.hl_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  opacity: 0.9;
  width: 460px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/U1e0bbbb7869149f59d9bb91d1875f796D.jpg");
}
.hl_item {
  transition: all 0.5s;
  position: relative;
  bottom: -40px;
}
.hl_box:hover .h4{
  transform: translateY(-60px);
}
.hl_box:hover .p1{
  transform: translateY(-90px);
}
.zn_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  opacity: 0.9;
  width: 540px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/index_link_3_1.jpg");
  /* padding: 0 40px; */
  box-sizing: border-box;
  z-index: 66;
}
/* .zn_item {
  transition: all 0.5s;
  position: relative;
  bottom: -320px;
} */
.h4{
  transition: all 0.5s;
  position: relative;
  bottom: -320px;
}
.p1{
  transition: all 0.5s;
  position: relative;
  bottom: -340px;
}
.zn_box:hover .h4{
  transform: translateY(-60px);
}
.zn_box:hover .p1{
  transform: translateY(-90px);
}
.zw_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  align-items: start;
  opacity: 0.9;
  width: 460px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/Ud9681dc6049b4aaaba242922a6412500x.jpg");
  padding: 0 40px;
  box-sizing: border-box;
}
.jsx_box {
  overflow: hidden;
  text-align: left;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  align-items: start;
  opacity: 0.9;
  width: 460px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/U9b6c9e6b707f4e3b8caccb6502692465o.jpg");
  padding: 0 40px;
  box-sizing: border-box;
}
/* .js_item {
  transition: all 0.5s;
  position: relative;
  bottom: -80px;
}
.jsx_box:hover .js_item {
  transform: translateY(-80px);
} */
.jsx_box:hover .h4{
  transform: translateY(-60px);
}
.jsx_box:hover .p1{
  transform: translateY(-90px);
}
.dj_box {
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  align-items: start;
  opacity: 0.9;
  width: 920px;
  height: 390px;
  background-image: url("http://www.haikoutour.cn/img/sy/vjialong.png");
  padding: 0 40px;
  box-sizing: border-box;
}
.dj_box:hover .h4{
  transform: translateY(-60px);
}
.dj_box:hover .p1{
  transform: translateY(-90px);
}
.zw_box:hover .h4{
  transform: translateY(-60px);
}
.zw_box:hover .p1{
  transform: translateY(-90px);
}
/* .zw_item {
  transition: all 0.5s;
  position: relative;
  bottom: -60px;
}
.zw_box:hover .zw_item {
  transform: translateY(-80px);
} */

.threebox {
  position: relative;
  /* left: -10px; */
  width: 100%;
  height: 260px;
  display: flex;
  box-sizing: border-box;
  /* transition: all 0.5s; */
  /* position: ; */
  animation: three 1s;
  animation-fill-mode: forwards;
}
@keyframes three {
  from {
    left: -20px;
  }
  to {
    left: 10px;
  }
}
.threebox2 {
  
  opacity: 1;
  position: relative;
  /* left: -10px; */
  width: 100%;
  height: 260px;
  display: flex;
  box-sizing: border-box;
  /* transition: all 0.5s; */
  /* position: ; */
  animation: three2 1s;
  animation-fill-mode: forwards;
  
}
@keyframes three2 {
  from {
    left: 10px;
  }
  to {
    left: -20px;
    opacity: 0.5;
  }
}
a{
  text-decoration: none;
}
.three_icon1{
  width: 50px;
                height: 70px;
                margin-left: 0px;
                border-left: 8px solid #0052d9;
                animation: threeicon1 0.5s;
  animation-fill-mode: forwards;
  /* opacity: 0.5; */
}
@keyframes threeicon1 {
  from {
    left: 10px;
    /* opacity: 0; */
  }
  to {
    opacity: 1;
    left: -20px;
  }
}
.three_icon2{
  width: 50px;
                height: 70px;
                margin-left: 0px;
                border-left: 8px solid #0052d9;
                animation: threeicon2 0.5s;
  animation-fill-mode: forwards;
}


@keyframes threeicon2 {
  from {
    left: -20px;
   
  }
  to {
    opacity: 0;
    left: 10px;
  }
}








.twobox {
  position: relative;
  /* left: -10px; */
  width: 100%;
  height: 260px;
  display: flex;
  box-sizing: border-box;
  /* transition: all 0.5s; */
  /* position: ; */
  animation: two 1s;
  animation-fill-mode: forwards;
}
@keyframes two {
  from {
    left: -20px;
  }
  to {
    left: 10px;
    opacity: 1;
  }
}
.twobox2 {
  opacity: 1;
  position: relative;
  /* left: -10px; */
  width: 100%;
  height: 260px;
  display: flex;
  box-sizing: border-box;
  /* transition: all 0.5s; */
  /* position: ; */
  animation: two2 1s;
  animation-fill-mode: forwards;
}
@keyframes two2 {
  from {
    left: 10px;
  }
  to {
    opacity: 0.5;
    left: -20px;
  }
}

.two_icon1{
  width: 50px;
                height: 70px;
                margin-left: 0px;
                border-left: 8px solid #0052d9;
                animation: twoicon 0.5s;
  animation-fill-mode: forwards;
  /* opacity: 0.5; */
}
@keyframes twoicon1 {
  from {
    left: 10px;
    /* opacity: 0; */
  }
  to {
    opacity: 1;
    left: -20px;
  }
}
.two_icon2{
  width: 50px;
                height: 70px;
                margin-left: 0px;
                border-left: 8px solid #0052d9;
                animation: twoicon2 0.5s;
  animation-fill-mode: forwards;
}


@keyframes twoicon2 {
  from {
    left: -20px;
    /* opacity: 0; */
  }
  to {
    opacity: 0;
    left: 10px;
  }
}






.ref_icon1{
  width: 50px;
                height: 70px;
                margin-left: 0px;
                border-left: 8px solid #0052d9;
                animation: oneicon 0.5s;
  animation-fill-mode: forwards;
  /* opacity: 0.5; */
}

@keyframes oneicon1 {
  from {
    left: 10px;
    /* opacity: 0; */
  }
  to {
    opacity: 1;
    left: -20px;
  }
}
.ref_icon2{
  width: 50px;
                height: 70px;
                margin-left: 0px;
                border-left: 8px solid #0052d9;
                animation: oneicon2 0.5s;
  animation-fill-mode: forwards;
}


@keyframes oneicon2 {
  from {
    left: -20px;
    /* opacity: 0; */
  }
  to {
    opacity: 0;
    left: 10px;
  }
}




.refbox {
  position: relative;
  /* left: -10px; */
  width: 100%;
  height: 260px;
  display: flex;
  box-sizing: border-box;
  /* transition: all 0.5s; */
  /* position: ; */
  animation: one 1s;
  animation-fill-mode: forwards;
  /* animation-direction: reverse; */
  
}
@keyframes one {
  from {
    left: -20px;
    /* opacity: 0; */
  }
  to {
    opacity: 1;
    left: 10px;
  }
}
.refbox2 {opacity: 0.5;
  position: relative;
  /* left: -10px; */
  width: 100%;
  height: 260px;
  display: flex;
  box-sizing: border-box;
  /* transition: all 0.5s; */
  /* position: ; */
  animation: one2 1s;
  animation-fill-mode: forwards;
}
@keyframes one2 {
  from {
    left: 10px;
  }
  to {
    left: -20px;
  }
}

.test {
  width: 0px;
  height: 200px;
  background: red;
  animation: mymove 1s;
  animation-fill-mode: forwards;
  /*Safari 和 Chrome:*/
  /* -webkit-animation:mymove 5s infinite; */
  position: relative;
  /* left: 0; */
}
.test2 {
  /* opacity: 0.5; */
  width: 300px;
  height: 200px;
  background: red;
  animation: mymove2 1s;
  animation-fill-mode: forwards;
  /*Safari 和 Chrome:*/
  /* -webkit-animation:mymove 5s infinite; */
  position: relative;
  /* animation-direction: reverse; */
  left: 100px;
}

@keyframes mymove {
  from {
    width: 0px;
  }
  to {
    /* opacity: 1; */
    width: 200px;
  }
}

* {
  margin: 0;
  padding: 0;
}
.index_box {
  width: 100%;
  height: 480px;
  background-color: black;
  margin: auto;
  /* position: absolute; */
  position: relative;
  top: 0;
}
.nav_box {
  
  width: 1920px;
  height: 310px;
  background-color: white;

}
.nav_item {
  width: 1200px;
  height: 100px;
  /* background-color: green; */
  display: flex;
  position: relative;
  /* top: -480px; */
  /* padding-right: 300px; */
  /* margin: auto; */
  /* background-color: yellow; */
  /* position: relative; */
  left: -355px;
  margin: auto;
}
.h_box {
  width: 300px;
  font-size: 24px;
  color: white;
}
.select_box {
  font-weight: 8000;
  margin-right: 10px;
  width: 100px;
  height: 100%;
  /* background-color: red; */
  text-align: center;
  line-height: 60px;
  /* margin-right: 50px; */
  font-size: 16px;
  font-weight: 800;
  color: white;
  margin-right: 35px;
  margin-top: 5px;
  /* overflow: hidden; */
  /* margin-right: 20px; */
  position: relative;
  left: 20px;
}
.contnet_box {
  top: 355px;
  width: 100%;
  height: 300px;
  /* background-color: green; */
  position: absolute;
  /* left: 50%; */
  font-size: 26px;
  font-weight: bold;
  color: white;
  margin: auto;
  text-align: center;
  /* position: absolute;
    top: 0px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin: auto;
    width: 100%;
    z-index: 20;
    padding-top: 350px;
    padding-bottom: 280px; */
}
.js_box {
  position: relative;
  top: 0;
  width: 100%;
  height: 400px;
  background-color: white;
  /* padding-top: 350px; */
  box-sizing: border-box;
  text-align: center;
  font-size: 23px;
  color: rgb(116, 117, 115);
  font-weight: 330;
  color: rgb(116, 117, 115);
  position: relative;
  /* top: 0; */
}
.serve_box {
  width: 100%;
  height: 650px;
  /* background: green; */
  background-image: url("http://www.haikoutour.cn/img/sy/mt.jpg");
  display: flex;
  /* align-items: end; */
  justify-content: center;
  overflow: hidden;
  position: relative;
  top: 0;
  /* position: absolute; */
  /* top: 2000px;
      margin: auto; */
}
.serve_bg{
  width: 320px;
  height: 310px;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* opacity: 0.1; */
  margin-right: 20px;
  position: relative;
  top: 330px;

}
.serve_item {
  width: 320px;
  height: 310px;
  background-color: white;
  margin-right: 20px;
  position: relative;
  top: 5px;
  transition: all 0.5s;
}
.serve_bg:hover .serve_item{
  /* animation: rotate360 .5s ease-out .0s;
      animation-fill-mode:forwards; */
  /* animation-direction: rever; */
  transform: translateY(-60px);
  
}
/* @keyframes rotate360 {
          100% {
              transform: translateY(-60px);
          }
          0%{
              transform: translateY(0px);
          }
      } */
.con_box {
  width: 100%;
  height: 995px;
  /* background-color: bisque; */
  background-color: rgb(24, 24, 24);
  padding-top: 100px;
  padding-bottom: 30px;
  position: relative;
  top: 0;
}
.con_item {
  width: 1440px;
  height: 780px;
  /* background-color: red; */
  margin: 0 auto;
  display: flex;
}
.left_box {
  width: 519px;
  height: 100%;
  /* background-color: yellow; */
}
.right_box {
  width: 921px;
  height: 100%;
  /* background-color: green; */
}

.wechtimg {
  float: left;
  background-image: url('http://www.haikoutour.cn/img/gywm/wct1.png');
  height: 25px;
  width: 30px;
  background-size: 100% 100%;
  margin-left: 30px;
  transition: all 0.2s linear;
  cursor: pointer;
}
.wechtimg:hover{
  background-image: url('http://www.haikoutour.cn/img/gywm/wct.png');
}
.bannertext1 {
  font-size: 14px;
  margin-top: 15px;
  cursor: pointer;
  color: rgb(124, 131, 131);
}
a {
  color: rgb(94, 100, 100);
}
.a-enter-active,
.a-leave-active {
  transition: all 0.5s linear;
}
.a-enter,
.a-leave-to {
  height: 0;
  opacity: 0;
}
.a-enter-to,
.a-leave {
  height: 300;
  opacity: 1;
}

.transition-box {
  /* margin-bottom: 10px; */
  width: 30px;
  height: 5px;
  border-radius: 4px;
  background-color: rgb(0,82,217);
  text-align: center;
  color: #fff;
  /* padding: 40px 20px; */
  box-sizing: border-box;
}

/* 1111111111 */
.leftone-enter-active,
.leftone-leave-active {
  transition: all 0.1s linear;
}
.leftone-enter,
.leftone-leave-to {
  /* width: 0; */
  transform: translateX(-20px);
  /* background-color: #409EFF; */
}
.leftone-enter-to,
.leftone-leave {
  transform: translateX(0px);
  /* width: 50px; */
  /* opacity: 1; */
  /* background-color: black; */
}

.leftonediv-enter-active,
.leftonediv-leave-active {
  transition: all 1s linear;
}
.leftonediv-enter,
.leftonediv-leave-to {
  /* width: 0; */
  transform: translateX(-20px);
  /* background-color: #409EFF; */
}
.leftonediv-enter-to,
.leftonediv-leave {
  transform: translateX(0px);
  /* width: 50px; */
  /* opacity: 1; */
  /* background-color: black; */
}

.leftoned-enter-active,
.leftoned-leave-active {
  transition: all 1s linear;
}
.leftoned-enter,
.leftoned-leave-to {
  /* width: 0; */
  left: 140;
  /* background-color: #409EFF; */
}
.leftoned-enter-to,
.leftoned-leave {
  left: 0;
  /* opacity: 1; */
  /* background-color: black; */
}

#davidszhou {
  width: 360px;
  height: auto;
}

#davidszhoua {
  width: 360px;
  height: 350px;
}

#zongk {
  width: 200px;
  float: left;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  transition-property: height;
  transition-duration: 0.5s;
  margin-top: 18px;
}

#zongk ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

#zongk ul li {
  width: 120px;
  height: 30px;
  line-height: 30px;
  float: left;
  /* background: #999; */
  color: black;
  text-align: center;
  font-weight: 330;
  margin-bottom: 10px;
  margin-left:20px ;
  position: relative;
  left: 30px;
}
#zongk ul li a {
  color: black;
  text-decoration: none;
}
#zongk:hover {
  height: 300px;
}
.li_box:hover{
  color: rgb(42, 127, 255);
}
</style>
  <!-- 
  .s10{
      width:100px;height: 100px;background-color: rgb(192, 33, 33);position: absolute;top:40px;right:1017px;
  }
  .s12{
      text-align: center;
      width:100px;height: 100px;position: absolute;top:40px;right:870px;
      background-color: rgb(192, 33, 33);
  }
  /* 旗下网站 */
  .wz{
      text-align: center;
      width:100px;height: 100px;position: absolute;top:40px;right:870px;
      background-color: rgb(192, 33, 33);text-align: center;
      width:100px;height: 100px;position: absolute;top:40px;right:720px;
      background-color: rgb(192, 33, 33);
  }
  /*  */
  .s-enter-active,.s-leave-active{
      transition: all 1s linear;
  }
  .s-enter,.s-leave-to{
      height: 0;
      /* opacity: 0; */
    
  }
  .s-enter-to,.s-leave{
   height: 100px;
      /* opacity: 1; */
    
  }
  .s2-enter-active,.s2-leave-active{
      transition: all 1s linear;
  }
  .s2-enter,.s2-leave-to{
      height: 0;
      /* opacity: 0; */
    
  }
  .s2-enter-to,.s2-leave{
   height: 100px;
      /* opacity: 1; */
    
  }
  
  .cp-enter-active,.cp-leave-active{
      transition: all 1s linear;
  }
  .cp-enter,.cp-leave-to{
   height: 0;
    
  }
  .cp-enter-to,.cp-leave{
  
  height: 100px;
      /* opacity: 1; */
    
  }
  /* 旗下网站 */
  .wz-enter-active,.wz-leave-active{
      transition: all 1s linear;
  }
  .wz-enter,.wz-leave-to{
   height: 0;
    
  }
  .wz-enter-to,.wz-leave{
  
  height: 100px;
      /* opacity: 1; */
    
  } -->