import { getdetails, getcollect, addcollect, subcollect, getroom, getcomment } from '../../api/details'
export const actions = {
    // 获取房间信息
    async getroom(context, datas) {
        let { title, sign } = datas;
        let data = await getroom(title, sign);
        if (data.data != '-1') {
            context.commit("GETROOM", data.data);
            console.log(data);
        }

    },
    // 获取酒店详情
    async getdetailslist(context, datas) {
        let { title, sign } = datas;
        let data = await getdetails(title, sign)
        if (data.data != '-1') {
            context.commit('GETDETAILSLIST', data.data[0])
        }
    },
    // 查看酒店收藏状态
    async getcollect(context, datas) {
        let hoteltitle = datas.title;
        let sign = datas.sign;
        let data = await getcollect({ hoteltitle, sign })

        if (data.data == '-1') {
            // console.log(data)
            context.commit('GETCOLLECT', -1)
        } else {
            // context.commit('GETCOLLECT', data.data)
            console.log(data.data[0]);
            context.commit('GETCOLLECT', data.data[0].state)
        }
    },
    // 添加收藏
    async addcollect(context, datas) {
        let hoteltitle = datas.title;
        let sign = datas.sign;
        let data = await addcollect({ hoteltitle, sign })
        if (data.data.affectedRows == 1) {
            return "收藏成功";
        } else {
            return "收藏失败";
        }

    },
    // 取消收藏
    async subcollect(context, datas) {
        let hoteltitle = datas.title;
        let sign = datas.sign;
        let data = await subcollect({ hoteltitle, sign });
        if (data.data.affectedRows == '1') {
            return "已取消收藏"
        } else {
            return "取消收藏失败"
        }

    },
    // 获取评论
    async getcomment(context, datas) {
        let { title, sign } = datas;
        let data = await getcomment(title, sign);
        console.log(data)
        if (data.data) {
            context.commit("GETCOMMENT", data.data);
        }

    }

}
export const mutations = {
    GETCOMMENT(state, data) {
        state.commentlist = data;
    },
    GETROOM(state, data) {
        state.roomtypelist = data;
    },
    GETDETAILSLIST(state, data) {
        state.detailslist = data;

    },
    GETCOLLECT(state, data) {
        state.collect = data;

    }

}
export const state = {
    detailslist: {},
    collect: '',
    roomtypelist: [],
    commentlist: [],

}
export const getters = {

}
export default {
    actions,
    mutations,
    state,
    getters
}