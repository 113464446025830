import Vue from "vue";
import Vuex from 'vuex'
import home from './home'
import headers from './header'
import search from './search'
import order from './order'
import details from './details'
import collect from "./collect";
import comment from "./comment";
import zwkj from "./zwkj";
Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        home,
        headers,
        search,
        order,
        details,
        collect,
        comment,
        zwkj
    }
})
export default store;