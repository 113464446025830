import axios from "axios";
// import stote from '../store/index'
import 'nprogress/nprogress.css'
import nprogress from 'nprogress'
const request = axios.create({
    baseURL: '',
    timeout: 5000,
})
request.interceptors.request.use(async(config) => {
    // config.headers.token = stote.state.headers.token;
    // console.log(config.headers.token)
    nprogress.start()
    return config
})
request.interceptors.response.use((resolve) => {
    nprogress.done()
    return resolve.data;
})
export default request;