import { getusercomment, deletecomment } from '@/api/comment/index'
const actions = {
    async getusercomment(context) {
        let data = await getusercomment();
        if (data.data != '-1') {
            context.commit("GETUSERCOMMENT", data.data)
        }
        // console.log(data)
    },
    async deletecomment(context, datas) {
        let userid = datas.userId;
        let orderid = datas.orderid;
        let data = await deletecomment(userid, orderid);
        if (data.data.affectedRows == '1') {
            return 1;
        } else {
            return 0;
        }
    }
}
const mutations = {
    GETUSERCOMMENT(state, data) {

        state.usercommentlist = data;
    }
}
const state = {
    usercommentlist: []
}
const getters = {}
export default {
    actions,
    mutations,
    state,
    getters
}