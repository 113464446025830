import { getlist } from '@/api/home/index'
const actions = {
    async gethotelList(context, ad) {
        const data = await getlist(ad);
        if (data.errno == 200) {
            context.commit('GETHOTELLIST', data.data)
        }

    }
}
const mutations = {
    GETHOTELLIST(state, data) {
        state.hotelList = data

    }

}
const state = {
    hotelList: [],

}
const getters = {

}
export default {
    actions,
    mutations,
    state,
    getters
}