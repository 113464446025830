import { getsearch } from '@/api/search/index'
const actions = {
    async getsearchlist(context, datas) {
        let { keyword, num } = datas
        const data = await getsearch(keyword, num);
        if (data.data != '-1') {
            console.log(data.data)
            context.commit('GETSEARCHLIST', data.data);

        }
    }
};
const mutations = {
    GETSEARCHLIST(state, datas) {
        let { data, data1 } = datas
        state.searchlist = data;
        state.nums = data1

    }
};
const state = {
    searchlist: [],
    nums: 0
};
const getters = {};
export default {
    actions,
    mutations,
    state,
    getters
}